<template>
    <v-sheet height="100%" class="d-flex flex-column" style="overflow: hidden">
        <v-layout column justify-center align-center fill-height style="overflow: auto">
            <i class="icon icon-handshake"></i>
            <h3 class="join-complete__title">회원가입이 완료 되었습니다.</h3>
            <p class="join-complete__text mt-8 mt-md-14 text-center">
                관리자 승인 후 기업서비스를 이용하실 수 있습니다<br />
                1~2 영업일 이내에 승인여부를 확인 하실 수 있습니다
            </p>
        </v-layout>
        <v-row no-gutters>
            <v-col> <v-btn x-large tile block color="primary lighten-4" @click="$emit('close')"> 이전 화면으로 돌아가기 </v-btn> </v-col>
            <v-col> <v-btn x-large tile block color="primary" @click="$emit('next')"> 대시보드로 가기 </v-btn> </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {};
</script>

<style>
</style>