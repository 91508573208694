<template>
    <bottom-sheet v-model="shows" v-bind="{ title }" fullscreen persistent width="720" maxWidth="100%">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item :value="tabs.USER_TYPE_BTN" class="fill-height">
                <user-type-btn v-model="userType" @input="next" />
            </v-tab-item>
            <v-tab-item :value="tabs.BASEINFO_FORM" class="fill-height">
                <person-baseinfo-form v-if="isPerson" isDialog v-on="{ prev, next }" @prev="userType = null" />
                <company-baseinfo-form v-if="isCompany" isDialog v-on="{ next }" />
            </v-tab-item>
            <v-tab-item :value="tabs.GREETING_CARD" class="fill-height">
                <person-greeting-card v-if="isPerson" v-on="{ close, next }" />
            </v-tab-item>
            <v-tab-item :value="tabs.DETAILED_FORM" class="fill-height">
                <person-detailed-form v-if="isPerson" isDialog v-on="{ next }" />
            </v-tab-item>
            <v-tab-item :value="tabs.COMPLETE_CARD" class="fill-height">
                <person-complete-card v-if="isPerson" v-bind="{ userType }" v-on="{ close, next: goDashboard }" />
                <company-complete-card v-if="isCompany" v-bind="{ userType }" v-on="{ close, next: goDashboard }" />
            </v-tab-item>
        </v-tabs-items>
    </bottom-sheet>
</template>

<script>
import { USER_TYPES } from "@/assets/variables";

import BottomSheet from "@/components/client/dumb/bottom-sheet.vue";
import UserTypeBtn from "./user-type-btn.vue";
import PersonBaseinfoForm from "./person-baseinfo-form.vue";
import PersonGreetingCard from "./person-greeting-card.vue";
import PersonDetailedForm from "./person-detailed-form.vue";
import PersonCompleteCard from "./person-complete-card.vue";
import CompanyBaseinfoForm from "./company-baseinfo-form.vue";
import CompanyCompleteCard from "./company-complete-card.vue";

const tabs = {
    USER_TYPE_BTN: "USER_TYPE_BTN",
    BASEINFO_FORM: "BASEINFO_FORM",
    GREETING_CARD: "GREETING_CARD",
    DETAILED_FORM: "DETAILED_FORM",
    COMPLETE_CARD: "COMPLETE_CARD",
};
const tabValues = Object.values(tabs);

export default {
    components: {
        BottomSheet,
        UserTypeBtn,
        PersonBaseinfoForm,
        PersonGreetingCard,
        PersonDetailedForm,
        PersonCompleteCard,
        CompanyBaseinfoForm,
        CompanyCompleteCard,
    },
    props: {
        type: { type: String, default: null }, // userType
    },
    data: () => ({
        userType: null,
        tab: tabs.USER_TYPE_BTN,
        tabs,
        shows: false,
    }),
    computed: {
        isPerson() {
            return this.userType == USER_TYPES.PERSON.value;
        },
        isCompany() {
            return this.userType == USER_TYPES.COMPANY.value;
        },
        tabSequence() {
            switch (this.userType) {
                case USER_TYPES.COMPANY.value: {
                    return [tabs.BASEINFO_FORM, tabs.COMPLETE_CARD];
                }
                case USER_TYPES.PERSON.value:
                default: {
                    return tabValues;
                }
            }
        },
        userTypeText() {
            return USER_TYPES[this.userType]?.text || "회원";
        },
        title() {
            return `${this.userTypeText}가입`;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        init() {
            this.userType = this.type;
            this.tab = this.userType ? tabs.BASEINFO_FORM : tabs.USER_TYPE_BTN;
        },
        prev() {
            let thisTabIndex = this.tabSequence.findIndex((item) => item == this.tab);
            this.tab = this.tabSequence[thisTabIndex - 1];
        },
        next() {
            let thisTabIndex = this.tabSequence.findIndex((item) => item == this.tab);
            this.tab = this.tabSequence[thisTabIndex + 1];
        },
        close() {
            this.shows = false;
        },
        goDashboard() {
            location.href = "/dashboard";
        },
    },
};
</script>

<style>
</style>