var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "height": "100%"
    }
  }, [_c('v-layout', {
    staticStyle: {
      "flex": "1 1 auto",
      "overflow": "auto"
    },
    attrs: {
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-handshake"
  }), _c('h3', {
    staticClass: "join-complete__title"
  }, [_vm._v("회원가입이 완료 되었습니다.")]), _c('p', {
    staticClass: "join-complete__text mt-8 mt-md-14 text-center"
  }, [_vm._v(" 추가정보를 입력하시면 보다 편리하게"), _c('br'), _vm._v(" 서비스를 이용하실 수 있습니다 ")])]), _c('v-row', {
    staticStyle: {
      "flex": "0 1 auto"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "tile": "",
      "block": "",
      "color": "primary lighten-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" 이전 화면으로 돌아가기 ")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "tile": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('next');
      }
    }
  }, [_vm._v(" 추가정보 입력하기 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }