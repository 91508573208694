var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "height": "100%"
    }
  }, [_c('v-layout', {
    staticStyle: {
      "overflow": "auto"
    },
    attrs: {
      "column": "",
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('i', {
    staticClass: "icon icon-handshake"
  }), _c('h3', {
    staticClass: "join-complete__title"
  }, [_vm._v("추가정보 입력이 완료 되었습니다.")])]), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "tile": "",
      "block": "",
      "color": "primary lighten-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" 이전 화면으로 돌아가기 ")])], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "tile": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('next');
      }
    }
  }, [_vm._v(" 대시보드로 가기 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }