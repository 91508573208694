var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column justify-center align-center",
    attrs: {
      "height": "100%"
    }
  }, [_c('v-row', {
    staticStyle: {
      "flex": "0 1 auto"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "join-gate-box join-gate-box--personal"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('h2', [_vm._v("개인회원")]), _c('i', {
    staticClass: "icon icon-chair"
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', _vm.USER_TYPES.PERSON.value);
      }
    }
  }, [_vm._v(" 가입하기 ")])], 1)])]), _c('v-col', [_c('div', {
    staticClass: "join-gate-box join-gate-box--business"
  }, [_c('div', {
    staticClass: "join-gate-box__inner"
  }, [_c('h2', [_vm._v("기업회원")]), _c('i', {
    staticClass: "icon icon-picture"
  }), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('input', _vm.USER_TYPES.COMPANY.value);
      }
    }
  }, [_vm._v(" 가입하기 ")])], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }