<template>
    <v-sheet height="100%" class="d-flex flex-column justify-center align-center">
        <v-row style="flex: 0 1 auto">
            <v-col>
                <div class="join-gate-box join-gate-box--personal">
                    <div class="join-gate-box__inner">
                        <h2>개인회원</h2>
                        <i class="icon icon-chair"></i>
                        <v-btn color="primary" @click="$emit('input', USER_TYPES.PERSON.value)"> 가입하기 </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col>
                <div class="join-gate-box join-gate-box--business">
                    <div class="join-gate-box__inner">
                        <h2>기업회원</h2>
                        <i class="icon icon-picture"></i>
                        <v-btn color="primary" @click="$emit('input', USER_TYPES.COMPANY.value)"> 가입하기 </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { USER_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: String }, // userType
    },
    data: () => ({
        USER_TYPES,
    }),
};
</script>

<style>
</style>