var render = function render(){
  var _vm$rules$_vm$PERSON$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    staticClass: "pa-16",
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "비밀번호 확인"
  }, [_c('v-password-confirm-field', {
    attrs: {
      "password": _vm.inputs.password,
      "outlined": ""
    }
  })], 1), _c('template', {
    slot: "성별"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.genders,
      "rules": (_vm$rules$_vm$PERSON$ = _vm.rules[_vm.PERSON.value]) === null || _vm$rules$_vm$PERSON$ === void 0 ? void 0 : _vm$rules$_vm$PERSON$.gender,
      "outlined": ""
    },
    model: {
      value: _vm.inputs.gender,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "gender", $$v);
      },
      expression: "inputs.gender"
    }
  })], 1), _c('template', {
    slot: "연락처"
  }, [_c('user-contact-certification', {
    attrs: {
      "outlined": "",
      "dense": "",
      "className": "rounded",
      "btnAttrs": {
        color: 'primary',
        width: 100,
        height: 50,
        className: 'mt-n8'
      }
    },
    model: {
      value: _vm.inputs.phone,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "phone", $$v);
      },
      expression: "inputs.phone"
    }
  })], 1)], 2), _c('terms-of-agreements-short', {
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.inputs._terms,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "_terms", $$v);
      },
      expression: "inputs._terms"
    }
  }), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('prev');
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "disabled": !_vm.isValid,
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.join
    }
  }, [_vm._v("가입하기")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }