<template>
    <v-sheet height="100%" class="d-flex flex-column" style="overflow: hidden">
        <v-layout column justify-center align-center style="flex: 1 1 auto; overflow: auto">
            <i class="icon icon-handshake"></i>
            <h3 class="join-complete__title">회원가입이 완료 되었습니다.</h3>
            <p class="join-complete__text mt-8 mt-md-14 text-center">
                추가정보를 입력하시면 보다 편리하게<br />
                서비스를 이용하실 수 있습니다
            </p>
        </v-layout>
        <v-row no-gutters style="flex: 0 1 auto">
            <v-col> <v-btn x-large tile block color="primary lighten-4" @click="$emit('close')"> 이전 화면으로 돌아가기 </v-btn> </v-col>
            <v-col> <v-btn x-large tile block color="primary" @click="$emit('next')"> 추가정보 입력하기 </v-btn> </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {};
</script>

<style>
</style>