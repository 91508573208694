var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('bottom-sheet', _vm._b({
    attrs: {
      "fullscreen": "",
      "persistent": "",
      "width": "720",
      "maxWidth": "100%"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'bottom-sheet', {
    title: _vm.title
  }, false), [_c('v-tabs-items', {
    staticClass: "fill-height",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    staticClass: "fill-height",
    attrs: {
      "value": _vm.tabs.USER_TYPE_BTN
    }
  }, [_c('user-type-btn', {
    on: {
      "input": _vm.next
    },
    model: {
      value: _vm.userType,
      callback: function ($$v) {
        _vm.userType = $$v;
      },
      expression: "userType"
    }
  })], 1), _c('v-tab-item', {
    staticClass: "fill-height",
    attrs: {
      "value": _vm.tabs.BASEINFO_FORM
    }
  }, [_vm.isPerson ? _c('person-baseinfo-form', _vm._g({
    attrs: {
      "isDialog": ""
    },
    on: {
      "prev": function ($event) {
        _vm.userType = null;
      }
    }
  }, {
    prev: _vm.prev,
    next: _vm.next
  })) : _vm._e(), _vm.isCompany ? _c('company-baseinfo-form', _vm._g({
    attrs: {
      "isDialog": ""
    }
  }, {
    next: _vm.next
  })) : _vm._e()], 1), _c('v-tab-item', {
    staticClass: "fill-height",
    attrs: {
      "value": _vm.tabs.GREETING_CARD
    }
  }, [_vm.isPerson ? _c('person-greeting-card', _vm._g({}, {
    close: _vm.close,
    next: _vm.next
  })) : _vm._e()], 1), _c('v-tab-item', {
    staticClass: "fill-height",
    attrs: {
      "value": _vm.tabs.DETAILED_FORM
    }
  }, [_vm.isPerson ? _c('person-detailed-form', _vm._g({
    attrs: {
      "isDialog": ""
    }
  }, {
    next: _vm.next
  })) : _vm._e()], 1), _c('v-tab-item', {
    staticClass: "fill-height",
    attrs: {
      "value": _vm.tabs.COMPLETE_CARD
    }
  }, [_vm.isPerson ? _c('person-complete-card', _vm._g(_vm._b({}, 'person-complete-card', {
    userType: _vm.userType
  }, false), {
    close: _vm.close,
    next: _vm.goDashboard
  })) : _vm._e(), _vm.isCompany ? _c('company-complete-card', _vm._g(_vm._b({}, 'company-complete-card', {
    userType: _vm.userType
  }, false), {
    close: _vm.close,
    next: _vm.goDashboard
  })) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }