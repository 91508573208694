<template>
    <bottom-sheet v-model="shows" title="아이디/ 비밀번호 찾기" maxWidth="424" fullscreen persistent color="primary">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-layout column justify-center fill-height>
            <v-img src="/images/ci.svg" contain class="mh-40px mh-lg-60px w-100 mb-30 mb-lg-100"></v-img>
            <v-tabs v-model="tab" hide-slider class="v-tabs--primary v-tabs--block">
                <v-tab>아이디 찾기</v-tab>
                <v-tab>비밀번호 찾기</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <find-id />
                </v-tab-item>
                <v-tab-item>
                    <find-password />
                </v-tab-item>
            </v-tabs-items>
            <div class="mt-8 mt-lg-12">
                <v-row no-gutters justify="center" align="center">
                    <v-btn @click="close" text x-small color="grey-6"><span class="font-weight-regular">로그인</span></v-btn>
                    <v-col cols="auto" class="mx-4">
                        <span class="menu-line"></span>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn text x-small color="grey-6"><span class="font-weight-regular" @click="$refs['dialog-join'].shows = true">회원가입</span></v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-layout>
        <dialog-join ref="dialog-join" />
    </bottom-sheet>
</template>

<script>
import FindId from "@/components/client/login/find-id.vue";
import FindPassword from "@/components/client/login/find-password.vue";

import DialogJoin from "@/components/client/join/dialog/join-dialog.vue";
import BottomSheet from "@/components/client/dumb/bottom-sheet.vue";

export default {
    components: {
        FindId,
        FindPassword,

        DialogJoin,
        BottomSheet,
    },
    data: () => ({
        shows: false,
        tab: null,
    }),
    methods: {
        close() {
            this.shows = false;
        },
    },
};
</script>

<style>
</style>