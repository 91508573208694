<template>
    <v-bottom-sheet v-model="shows" v-bind="$attrs" style="z-index:20000">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, userTypeMatches }" />
        </template>
        <v-sheet class="fill-height">
            <v-layout column fill-height>
                <bottom-sheet-header>
                    {{ title }}
                    <v-btn small text fab tile absolute right color="white" @click="close"> <v-icon>mdi-close</v-icon> </v-btn>
                </bottom-sheet-header>
                <div style="flex: 1 1 auto; overflow-y: auto">
                    <template v-if="showsContents">
                        <v-sheet v-bind="{ width, maxWidth }" class="mx-auto fill-height">
                            <slot />
                        </v-sheet>
                    </template>
                    <template v-else-if="!userHasLoggedIn">
                        <v-layout fill-height column justify-center align-center>
                            <v-sheet width="400" max-width="100%">
                                <login-card />
                            </v-sheet>
                        </v-layout>
                    </template>
                    <temlpate v-else>
                        <v-layout fill-height column justify-center align-center>
                            <v-sheet width="400" max-width="100%">
                                <v-responsive :aspect-ratio="4 / 3">
                                    <v-sheet class="d-flex flex-column justify-center text-center fill-height">
                                        <span class="font-size-20">
                                            <b> {{ storeUserTypeText ?? "관리자" }} </b> 사용자 유형으로는 <br />
                                            사용할 수 없는 기능입니다.
                                        </span>
                                    </v-sheet>
                                </v-responsive>
                            </v-sheet>
                        </v-layout>
                    </temlpate>
                </div>
            </v-layout>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import { USER_TYPES } from "@/assets/variables";

import BottomSheetHeader from "./bottom-sheet-header.vue";

export default {
    components: {
        BottomSheetHeader,
        LoginCard: () => import("@/components/client/login/login-form.vue"),
    },
    props: {
        value: { type: Boolean, default: false }, // shows
        title: { type: String },
        width: { type: [Number, String], default: undefined },
        userType: { type: [Array, String], default: null },
        maxWidth: { type: [Number, String], default: undefined },
        needsLogin: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
    }),
    computed: {
        doesNotMatch() {
            return this.shows != this.value;
        },
        storeUserType() {
            return this.$store.state.payload?.userType;
        },
        storeUserTypeText() {
            return USER_TYPES[this.storeUserType]?.text;
        },
        userHasLoggedIn() {
            return this.$store.state.payload?._user;
        },
        userTypeMatches() {
            if (!this.userType) return true;
            if (typeof this.userType == "string") return this.userType == this.storeUserType;
            if (Array.isArray(this.userType)) return this.userType.includes(this.storeUserType);
        },
        showsContents() {
            return !this.needsLogin || (this.userHasLoggedIn && this.userTypeMatches);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            if (this.doesNotMatch) this.sync();
        },
        shows() {
            if (this.doesNotMatch) this.emit();
        },
    },
    methods: {
        sync() {
            this.shows = this.value;
        },
        emit() {
            this.$emit("input", this.shows);
        },
        close() {
            this.shows = false;
        },
    },
};
</script>
