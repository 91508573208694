
<template>
    <v-form class="pa-16">
        <vertical-form-table v-model="inputs" v-bind="{ items }">
            <template slot="주소">
                <v-address-field v-model="inputs" outlined dense className="rounded" :btnAttrs="{ color: 'primary', width: 100, height: 50, className: 'mt-n8' }" />
            </template>
            <template slot="프로필사진">
                <v-file-btn v-model="inputs.profile" accept="image/*" plain text block height="auto" class="pa-0">
                    <v-img :src="profileSrc" width="100%" :aspect-ratio="1 / 1" class="v-sheet--outlined mx-auto rounded-xl">
                        <v-fade-transition hide-on-leave>
                            <v-layout v-show="!profileSrc" column align-center justify-center fill-height>
                                <v-icon x-large>mdi-image</v-icon>
                                <span>프로필사진</span>
                            </v-layout>
                        </v-fade-transition>
                    </v-img>
                </v-file-btn>
            </template>
        </vertical-form-table>

        <v-btn :disabled="!isValid" @click="save" x-large block tile class="primary white--text">저장하기</v-btn>
    </v-form>
</template>

<script>
import api from "@/api";
import axios from "@/plugins/axios";
import { attrs_input, rules, USER_FILE_TYPES } from "@/assets/variables";

import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const initInputs = () => ({
    email: null,
    postcode: null,
    address1: null,
    address2: null,

    profile: null,
});

const items = [
    {
        key: "email",
        term: "이메일",
        type: "email",
    },
    {
        term: "주소",
    },
    {
        term: "프로필사진",
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        VFileBtn,
        VAddressField,
        VerticalFormTable,
    },
    props: {
        isDialog: { type: Boolean, default: false },
    },
    data: () => ({
        items,
        rules,
        attrs_input,

        inputs: initInputs(),
        isValid: true,
    }),
    computed: {
        accessToken() {
            return this.$route.query.accessToken || this.$store.state.accessToken;
        },
        profileSrc() {
            return this.inputs.profile instanceof File ? URL.createObjectURL(this.inputs.profile) : this.inputs.profile?.url || null;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.accessToken) {
                try {
                    const { user = {} } = (await Axios.get("/api/v1/me", { headers: { Authorization: `Bearer ${this.accessToken}` } }))?.data || {};
                    let { _id, profile, email, postcode, address1, address2 } = user;
                    this.inputs = { ...initInputs(), _id, profile, email, postcode, address1, address2 };
                } catch (error) {
                    this.$handleError(error);
                    if (!this.isDialog) this.$router.push("/join/intro");
                }
            } else {
                this.inputs = initInputs();
            }
        },

        async save() {
            if (!this.validates()) return;
            let { profile, email, postcode, address1, address2 } = this.inputs;
            if (profile instanceof File) profile = (await api.v1.me.files.post({ type: USER_FILE_TYPES.PROFILE.value }, profile))?.file;

            await api.v1.me.put({ _profile: profile?._id, email, postcode, address1, address2 });

            if (!this.isDialog) this.$router.push("/join/complete");
            else this.$emit("next");
        },

        validates() {
            try {
            } catch (error) {
                alert(error.message);
                return false;
            }
            return true;
        },
    },
};
</script>
