
<template>
    <v-form v-model="isValid" class="pa-16">
        <vertical-form-table v-model="inputs" :items="items1">
            <template slot="비밀번호 확인">
                <v-password-confirm-field :password="inputs.password" outlined />
            </template>
            <template slot="연락처">
                <user-contact-certification v-model="inputs.phone" outlined dense className="rounded" :btnAttrs="{ color: 'primary', width: 100, height: 50, className: 'mt-n8' }" @change="inputs = { ...inputs }" />
            </template>
        </vertical-form-table>

        <v-card-title>기업정보입력</v-card-title>
        <vertical-form-table v-model="inputs" :items="items2">
            <template slot="사업자등록증">
                <v-file-input v-model="inputs.businessRegistration" outlined dense class="rounded" :prepend-icon="null" prepend-inner-icon="mdi-file-upload-outline" :rules="rules[COMPANY.value]?.businessRegistration" @change="inputs = { ...inputs }" />
            </template>
            <template slot="주소">
                <v-address-field v-model="inputs" outlined dense className="rounded" :btnAttrs="{ color: 'primary', width: 100, height: 50, className: 'mt-n8' }" />
            </template>
        </vertical-form-table>

        <!-- 약관동의 -->
        <terms-of-agreements-short v-model="inputs._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
        <v-btn :disabled="!isValid" @click="join" x-large block tile class="primary white--text">회원가입</v-btn>
    </v-form>
</template>

<script>
import api from "@/api";
import axios from "@/plugins/axios";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input, rules, USER_FILE_TYPES, USER_TYPES } from "@/assets/variables";

import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";
import UserContactCertification from "@/components/dumb/user-contact-certification.vue";

const { COMPANY } = USER_TYPES;

const initInputs = () => ({
    _terms: [],
    businessRegistration: null,

    username: null,
    password: null,

    name: null,
    phone: null,
    email: null,
    ceoName: null,
    website: null,

    code: COMPANY.value,
});

const items1 = [
    {
        key: "username",
        term: "아이디",
        type: "text",
        required: true,
        placeholder: "아이디를 입력하세요",
        rules: rules.username,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        required: true,
        placeholder: "비밀번호를 입력하세요",
        rules: rules.password,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: true,
    },
    {
        key: "phone",
        term: "연락처",
        required: true,
    },
].map((item) => ({ ...item, outlined: true }));

const items2 = [
    {
        key: "name",
        term: "기업명",
        type: "text",
        required: true,
        placeholder: "아이디를 입력하세요",
        rules: rules[COMPANY.value]?.name,
    },
    {
        key: "businessRegistration",
        term: "사업자등록증",
        required: true,
        rules: rules[COMPANY.value]?.businessRegistration,
    },
    {
        key: "ceoName",
        term: "대표자 성명",
        type: "text",
        required: true,
        rules: rules[COMPANY.value]?.ceoName,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
        rules: rules.email,
    },
    {
        key: "website",
        term: "홈페이지 주소",
        type: "text",
        required: true,
    },
    {
        key: "address",
        term: "주소",
        required: true,
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        VAddressField,
        VerticalFormTable,
        VPasswordConfirmField,
        TermsOfAgreementsShort,
        UserContactCertification,
    },
    props: {
        isDialog: { type: Boolean, default: false },
    },
    data: () => ({
        items1,
        items2,

        rules,
        COMPANY,
        attrs_input,

        inputs: initInputs(),
        isValid: true,
        isTermsValid: undefined,
    }),
    computed: {
        accessToken() {
            return this.$route.query.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.accessToken) {
                try {
                    const { user = {} } = (await Axios.get("/api/v1/me", { headers: { Authorization: `Bearer ${this.accessToken}` } }))?.data || {};
                    let { _id, bornAt, gender } = user;
                    bornAt = bornAt?.toDate?.() || bornAt;
                    this.inputs = { ...initInputs(), _id, bornAt, gender };
                } catch (error) {
                    this.$handleError(error);
                    if (!this.isDialog) this.$router.push("/join/intro");
                }
            } else {
                this.inputs = initInputs();
            }
        },

        async join() {
            if (!this.validates()) return;
            let { businessRegistration, password, ...inputs } = this.inputs;
            password = CryptoAES.encrypt(password);
            let { user } = await api.v1.users.post({ ...inputs, password });
            if (businessRegistration) await api.v1.users.files.post({ _user: user._id, type: USER_FILE_TYPES.BUSINESS_REGISTRATION.value, password }, businessRegistration);

            if (!this.isDialog) this.$router.push("/join/complete");
            else this.$emit("next");
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
            } catch (error) {
                alert(error.message);
                return false;
            }
            return true;
        },
    },
};
</script>
