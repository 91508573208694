
<template>
    <v-form v-model="isValid" class="pa-16">
        <vertical-form-table v-model="inputs" v-bind="{ items }">
            <template slot="비밀번호 확인">
                <v-password-confirm-field :password="inputs.password" outlined />
            </template>
            <template slot="성별">
                <v-select v-model="inputs.gender" :items="genders" :rules="rules[PERSON.value]?.gender" outlined />
            </template>
            <template slot="연락처">
                <user-contact-certification v-model="inputs.phone" outlined dense className="rounded" :btnAttrs="{ color: 'primary', width: 100, height: 50, className: 'mt-n8' }" />
            </template>
        </vertical-form-table>

        <!-- 약관동의 -->
        <terms-of-agreements-short v-model="inputs._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
        <v-card-actions class="justify-center">
            <v-btn x-large outlined @click="$emit('prev')">취소</v-btn>
            <v-btn :disabled="!isValid" x-large color="primary" @click="join">가입하기</v-btn>
        </v-card-actions>
    </v-form>
</template>

<script>
import api from "@/api";
import axios from "@/plugins/axios";
import { mapActions } from "vuex";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input, rules, USER_TYPES } from "@/assets/variables";

import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";
import UserContactCertification from "@/components/dumb/user-contact-certification.vue";

const { PERSON } = USER_TYPES;
const genders = ["남성", "여성"];

const initInputs = () => ({
    _terms: [],

    username: null,
    password: null,

    name: null,
    phone: null,
    bornAt: null,
    gender: null,
    code: PERSON.value,
});

const items = [
    {
        key: "username",
        term: "아이디",
        type: "text",
        required: true,
        placeholder: "아이디를 입력하세요",
        rules: rules.username,
    },
    {
        key: "password",
        term: "비밀번호",
        type: "password",
        required: true,
        placeholder: "비밀번호를 입력하세요",
        rules: rules.password,
    },
    {
        key: "password",
        term: "비밀번호 확인",
        required: true,
    },
    {
        key: "name",
        term: "이름",
        type: "text",
        required: true,
        rules: rules[PERSON.value]?.name,
    },
    {
        key: "bornAt",
        term: "생년월일",
        type: "date",
        required: true,
        rules: rules[PERSON.value]?.bornAt,
    },
    {
        key: "gender",
        term: "성별",
        required: true,
    },
    {
        key: "phone",
        term: "연락처",
        required: true,
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        VPasswordField,
        VerticalFormTable,
        VPasswordConfirmField,
        TermsOfAgreementsShort,
        UserContactCertification,
    },
    props: {
        isDialog: { type: Boolean, default: false },
    },
    data: () => ({
        rules,
        PERSON,
        genders,
        attrs_input,
        items,

        inputs: initInputs(),
        isValid: true,
        isTermsValid: undefined,
    }),
    computed: {
        accessToken() {
            return this.$route.query.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["login"]),

        async init() {
            if (this.accessToken) {
                try {
                    const { user = {} } = (await Axios.get("/api/v1/me", { headers: { Authorization: `Bearer ${this.accessToken}` } }))?.data || {};
                    let { _id, bornAt, gender } = user;
                    bornAt = bornAt?.toDate?.() || bornAt;
                    this.inputs = { ...initInputs(), _id, bornAt, gender };
                } catch (error) {
                    this.$handleError(error);
                    if (!this.isDialog) this.$router.push("/join/intro");
                }
            } else {
                this.inputs = initInputs();
            }
        },

        async join() {
            if (!this.validates()) return;
            const inputs = {
                ...this.inputs,
                password: CryptoAES.encrypt(this.inputs.password),
            };
            await api.v1.users.post(inputs);

            if (!this.isDialog) this.$router.push("/join/complete");
            else {
                let { username, password } = this.inputs;
                await this.login({ username, password });
                this.$emit("next");
            }
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
            } catch (error) {
                alert(error.message);
                return false;
            }
            return true;
        },
    },
};
</script>
