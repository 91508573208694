var render = function render(){
  var _vm$storeUserTypeText;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', _vm._b({
    staticStyle: {
      "z-index": "20000"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          userTypeMatches: _vm.userTypeMatches
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'v-bottom-sheet', _vm.$attrs, false), [_c('v-sheet', {
    staticClass: "fill-height"
  }, [_c('v-layout', {
    attrs: {
      "column": "",
      "fill-height": ""
    }
  }, [_c('bottom-sheet-header', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-btn', {
    attrs: {
      "small": "",
      "text": "",
      "fab": "",
      "tile": "",
      "absolute": "",
      "right": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticStyle: {
      "flex": "1 1 auto",
      "overflow-y": "auto"
    }
  }, [_vm.showsContents ? [_c('v-sheet', _vm._b({
    staticClass: "mx-auto fill-height"
  }, 'v-sheet', {
    width: _vm.width,
    maxWidth: _vm.maxWidth
  }, false), [_vm._t("default")], 2)] : !_vm.userHasLoggedIn ? [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "width": "400",
      "max-width": "100%"
    }
  }, [_c('login-card')], 1)], 1)] : _c('temlpate', [_c('v-layout', {
    attrs: {
      "fill-height": "",
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "width": "400",
      "max-width": "100%"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 4 / 3
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column justify-center text-center fill-height"
  }, [_c('span', {
    staticClass: "font-size-20"
  }, [_c('b', [_vm._v(" " + _vm._s((_vm$storeUserTypeText = _vm.storeUserTypeText) !== null && _vm$storeUserTypeText !== void 0 ? _vm$storeUserTypeText : "관리자") + " ")]), _vm._v(" 사용자 유형으로는 "), _c('br'), _vm._v(" 사용할 수 없는 기능입니다. ")])])], 1)], 1)], 1)], 1)], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }