<template>
    <v-card flat class="w-100" v-bind="$attrs">
        <slot />
        <v-tabs v-model="type" hide-slider class="v-tabs--primary v-tabs--block">
            <v-tab :value="item.value" v-for="item in userTypes" :key="item.value">{{ item.text }}</v-tab>
        </v-tabs>
        <v-text-field v-model="username" outlined hide-details placeholder="아이디" type="text" class="mt-20 mt-lg-40" />
        <v-text-field v-model="password" @keydown.enter="login" outlined hide-details placeholder="비밀번호" type="password" class="mt-8 mt-lg-12" />
        <v-checkbox v-model="remember" :value="true" label="로그인 유지" hide-details class="mt-8 mt-lg-12" />
        <v-btn @click="login" large color="primary" class="w-100 mt-14 mt-lg-24">로그인</v-btn>
        <div class="mt-8 mt-lg-12">
            <v-row no-gutters justify="center" align="center">
                <v-col cols="auto">
                    <find-dialog>
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" text x-small color="grey-6"><span class="font-weight-regular">아이디/비밀번호찾기</span></v-btn>
                        </template>
                    </find-dialog>
                </v-col>
                <v-col cols="auto" class="mx-4">
                    <span class="menu-line"></span>
                </v-col>
                <v-col cols="auto">
                    <v-btn text x-small color="grey-6"><span class="font-weight-regular" @click="$refs['dialog-join'].shows = true">회원가입</span></v-btn>
                </v-col>
            </v-row>
        </div>
        <join-dialog ref="dialog-join" />
    </v-card>
</template>

<script>
import CryptoAES from "@/plugins/crypto-aes";
import { USER_TYPES } from "@/assets/variables";

import JoinDialog from "@/components/client/join/dialog/join-dialog.vue";
import FindDialog from "@/components/client/login/find-dialog.vue";

const userTypes = Object.values(USER_TYPES);

export default {
    components: {
        JoinDialog,
        FindDialog,
    },
    data: () => ({
        type: USER_TYPES.PERSON.value,
        username: null,
        password: null,
        remember: false,

        userTypes,
    }),
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (this.$cookies.get("username")) this.username = this.$cookies.get("username");
            // if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
        },
        async login() {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$emit("loggedIn");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>

<style></style>
