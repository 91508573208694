var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', _vm._b({}, 'span', _vm.$attrs, false), [_vm._v(_vm._s(_vm.remained))]);

}
var staticRenderFns = []

export { render, staticRenderFns }