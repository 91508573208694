var render = function render(){
  var _vm$rules$_vm$COMPANY;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    staticClass: "pa-16",
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('vertical-form-table', {
    attrs: {
      "items": _vm.items1
    },
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, [_c('template', {
    slot: "비밀번호 확인"
  }, [_c('v-password-confirm-field', {
    attrs: {
      "password": _vm.inputs.password,
      "outlined": ""
    }
  })], 1), _c('template', {
    slot: "연락처"
  }, [_c('user-contact-certification', {
    attrs: {
      "outlined": "",
      "dense": "",
      "className": "rounded",
      "btnAttrs": {
        color: 'primary',
        width: 100,
        height: 50,
        className: 'mt-n8'
      }
    },
    on: {
      "change": function ($event) {
        _vm.inputs = Object.assign({}, _vm.inputs);
      }
    },
    model: {
      value: _vm.inputs.phone,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "phone", $$v);
      },
      expression: "inputs.phone"
    }
  })], 1)], 2), _c('v-card-title', [_vm._v("기업정보입력")]), _c('vertical-form-table', {
    attrs: {
      "items": _vm.items2
    },
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, [_c('template', {
    slot: "사업자등록증"
  }, [_c('v-file-input', {
    staticClass: "rounded",
    attrs: {
      "outlined": "",
      "dense": "",
      "prepend-icon": null,
      "prepend-inner-icon": "mdi-file-upload-outline",
      "rules": (_vm$rules$_vm$COMPANY = _vm.rules[_vm.COMPANY.value]) === null || _vm$rules$_vm$COMPANY === void 0 ? void 0 : _vm$rules$_vm$COMPANY.businessRegistration
    },
    on: {
      "change": function ($event) {
        _vm.inputs = Object.assign({}, _vm.inputs);
      }
    },
    model: {
      value: _vm.inputs.businessRegistration,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "businessRegistration", $$v);
      },
      expression: "inputs.businessRegistration"
    }
  })], 1), _c('template', {
    slot: "주소"
  }, [_c('v-address-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "className": "rounded",
      "btnAttrs": {
        color: 'primary',
        width: 100,
        height: 50,
        className: 'mt-n8'
      }
    },
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  })], 1)], 2), _c('terms-of-agreements-short', {
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      }
    },
    model: {
      value: _vm.inputs._terms,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "_terms", $$v);
      },
      expression: "inputs._terms"
    }
  }), _c('v-btn', {
    staticClass: "primary white--text",
    attrs: {
      "disabled": !_vm.isValid,
      "x-large": "",
      "block": "",
      "tile": ""
    },
    on: {
      "click": _vm.join
    }
  }, [_vm._v("회원가입")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }