<template>
    <span v-bind="$attrs">{{ remained }}</span>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        endsAt: { type: String },
    },
    data: () => ({
        remained: null,
        interval: null,
    }),
    mounted() {
        this.interval = setInterval(this.setRemained, 1000);
    },
    destroyed() {
        if (this.interval != null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    },
    methods: {
        setRemained() {
            const now = dayjs();
            const endsAt = dayjs(this.endsAt);

            const diff = endsAt.diff(now, "day", true);
            if (diff < 0) {
                this.remained = "00:00";
                clearInterval(this.interval);
                this.interval = null;
                this.$emit("ended");
                return;
            }

            const d = Math.floor(diff);
            const h = Math.floor((diff - d) * 24);
            const m = Math.floor(((diff - d) * 24 - h) * 60);
            const s = Math.floor((((diff - d) * 24 - h) * 60 - m) * 60);

            let remained = "";
            if (d) remained += `${String(d).padStart(2, "0")}:`;
            if (h) remained += `${String(h).padStart(2, "0")}:`;
            if (m) remained += `${String(m).padStart(2, "0")}:`;
            if (s) remained += `${String(s).padStart(2, "0")}`;
            this.remained = remained;
        },
    },
};
</script>

<style></style>
