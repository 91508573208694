var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    staticClass: "pa-16"
  }, [_c('vertical-form-table', _vm._b({
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "주소"
  }, [_c('v-address-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "className": "rounded",
      "btnAttrs": {
        color: 'primary',
        width: 100,
        height: 50,
        className: 'mt-n8'
      }
    },
    model: {
      value: _vm.inputs,
      callback: function ($$v) {
        _vm.inputs = $$v;
      },
      expression: "inputs"
    }
  })], 1), _c('template', {
    slot: "프로필사진"
  }, [_c('v-file-btn', {
    staticClass: "pa-0",
    attrs: {
      "accept": "image/*",
      "plain": "",
      "text": "",
      "block": "",
      "height": "auto"
    },
    model: {
      value: _vm.inputs.profile,
      callback: function ($$v) {
        _vm.$set(_vm.inputs, "profile", $$v);
      },
      expression: "inputs.profile"
    }
  }, [_c('v-img', {
    staticClass: "v-sheet--outlined mx-auto rounded-xl",
    attrs: {
      "src": _vm.profileSrc,
      "width": "100%",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-layout', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.profileSrc,
      expression: "!profileSrc"
    }],
    attrs: {
      "column": "",
      "align-center": "",
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-image")]), _c('span', [_vm._v("프로필사진")])], 1)], 1)], 1)], 1)], 1)], 2), _c('v-btn', {
    staticClass: "primary white--text",
    attrs: {
      "disabled": !_vm.isValid,
      "x-large": "",
      "block": "",
      "tile": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }